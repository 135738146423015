<template>
    <content-view class="staff__view">
        <staff-view></staff-view>
    </content-view>
</template>

<script>
    import { metaTitle } from '@/mixins/meta_title'

    export default {
        name: 'Staff',
        mixins: [metaTitle],
        components: {
            ContentView: () => import('@/views/menu/ContentView'),
            StaffView: () => import('@/components/staff/list/Content')
        }
    }
</script>

<style scoped>
    .staff__view {
        min-height: 100vh;
    }
</style>
